<template>
  <section>

    <h2 class="titulo-links-escolha">Notas    </h2>


    <div :class="'row'" v-if="acesso.visualizar">
      <div>
        <div class="row">

          <div :class="'col-sm-4 col-md-12 col-lg-6 col-xl-6 col-xxl-3'">
            <div class="pm-card">
              <div class="col-md-12" style="margin-bottom:20px;">
                <label for="escola">Selecione uma escola:</label>
                <select class="p-inputtext p-component" v-model="escola_id" @change="/*limparCampos()*/buscarSegmentoALLNovo()"
                  style="appearance: revert !important;width: 100% !important;" >
                  <option value="" disabled selected> -- Escolha uma Escola -- </option>
                  <option v-for="n in escolas" :key="n" :value="n.id" >{{n.nome}}</option>
                </select>
              </div>
            </div>
          </div>

          <div :class="'col-sm-4 col-md-6 col-lg-6 col-xl-6 col-xxl-3'" v-if="escola_id">
            <div class="pm-card">
              <div class="col-md-12" style="margin-bottom:20px;">
                <label for="segmento">Selecione um segmento:</label>
                <select class="p-inputtext p-component" v-model="segmentoInfo"
                  style="appearance: revert !important;width: 100% !important;" @change="listaSerie(segmentoInfo)">
                  <option value="" disabled selected> -- Escolha um Segmento -- </option>
                  <option v-for="s in segmentoALL" :key="s" :value="s.id" >{{s.nome}}</option>
                </select>
              </div>
            </div>
          </div>

          <div :class="'col-sm-4 col-md-6 col-lg-4 col-xl-4 col-xxl-2'" v-if="segmentoInfo">
            <div class="pm-card">
              <div class="col-md-12" style="margin-bottom:20px;">
                <label for="serie">Selecione um período:</label>
                <select class="p-inputtext p-component" v-model="serieInfo"
                  style="appearance: revert !important;width: 100% !important;" @change="listaTurmas()" >
                  <option value="" disabled selected> -- Escolha um Período -- </option>
                  <option v-for="a in serieALL" :key="a" :value="a.id" >{{a.nome}}</option>
                </select>
              </div>
            </div>
          </div>

          <div :class="'col-sm-4 col-md-6 col-lg-4 col-xl-4 col-xxl-2'" v-if="serieInfo">
            <div class="pm-card">
              <div class="col-md-12" style="margin-bottom:20px;">
                <label for="turma">Selecione uma turma:</label>
                <select class="p-inputtext p-component" v-model="turma_id" @change="turma_disciplinas(turma_id);buscarProfessores()"
                  style="appearance: revert !important;width: 100% !important;" >
                  <option value="" disabled selected> -- Escolha uma Turma -- </option>
                  <option v-for="n in turmaALL" :key="n" :value="n.id" >{{n.nome}}</option>
                </select>
              </div>
            </div>
          </div>

          <div :class="'col-sm-4 col-md-6 col-lg-4 col-xl-4 col-xxl-2'" v-if="turma_id != null">
            <div class="pm-card">
              <div class="col-md-12" style="margin-bottom:20px;">
                <label>Selecione uma Disciplina:</label>
                <select class="p-inputtext p-component"
                  v-model="disciplina" @change="buscarNotasDoAluno()"
                  style="appearance: revert !important;width: 100% !important;"
                >
                  <option value="" disabled selected> -- Escolha a disciplina -- </option>
                  <option  :value="n" v-for="n in disciplinaALL" :key="n">{{n.nome}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!pageReload">
      <div v-if="escola_id != '' && segmentoInfo != '' && serieInfo != '' && disciplina != '' && turma_id != null">
        <!--Fundamental 1-->
        <div v-if="tipoTabela == 1">
          <NotasFundamentalUmOuERN
            :periodoSelecionado="periodoSelecionado"
            :isLoadingPage="isLoadingPage"
            :acesso="acesso"
            :encerrado="encerrado"
            :displayedRecords="displayedRecords"
            @ajustarInfo="ajustarInfo"
            @adicionarEditarRecuperacaoFinal="adicionarEditarRecuperacaoFinal"
            @adicionarEditarSimulado="adicionarEditarSimulado"
            @adicionarEditarAtividade="adicionarEditarAtividade"
            @adicionarEditarAvaliacao="adicionarEditarAvaliacao"
            @adicionarEditarRecuperacao="adicionarEditarRecuperacao"
            @mudarParaRetidoOuDependencia="mudarParaRetidoOuDependencia"
            @sendNotes="sendNotes"
            @reset="reset"
          />
        </div>
        <!-- Fundamel 2 -->
        <div v-if="tipoTabela == 2">
          <NotasFundamentalDois
            :periodoSelecionado="periodoSelecionado"
            :isLoadingPage="isLoadingPage"
            :acesso="acesso"
            :encerrado="encerrado"
            :displayedRecords="displayedRecords"
            @ajustarInfo="ajustarInfo"
            @adicionarEditarRecuperacaoFinal="adicionarEditarRecuperacaoFinal"
            @adicionarEditarSimulado="adicionarEditarSimulado"
            @adicionarEditarAtividade="adicionarEditarAtividade"
            @adicionarEditarAvaliacao="adicionarEditarAvaliacao"
            @adicionarEditarRecuperacao="adicionarEditarRecuperacao"
            @mudarParaRetidoOuDependencia="mudarParaRetidoOuDependencia"
            @sendNotes="sendNotes"
            @reset="reset"
          />
        </div>
        <!-- Eja 1 -->
        <div v-if="tipoTabela == 3 && estiloTabelaEJA == 0">
          <NotasEjaFundamentalUm
            :tipoTabela="tipoTabela"
            :isLoadingPage="isLoadingPage"
            :displayedRecords="displayedRecords"
            :acesso="acesso"
            :encerrado="encerrado"
            @ajustarInfo="ajustarInfo"
            @adicionarEditarRecuperacaoFinal="adicionarEditarRecuperacaoFinal"
            @adicionarEditarSimulado="adicionarEditarSimulado"
            @adicionarEditarAtividade="adicionarEditarAtividade"
            @adicionarEditarAvaliacao="adicionarEditarAvaliacao"
            @adicionarEditarRecuperacao="adicionarEditarRecuperacao"
            @mudarParaRetidoOuDependencia="mudarParaRetidoOuDependencia"
            :notaconfigperiodo="notaconfigperiodo"
            :estiloTabelaEJA ="0"
            @sendNotes="sendNotes"
            @reset="reset"
          />
        </div>
        <!--EJA 2 -->
        <div v-if="tipoTabela == 3 && estiloTabelaEJA == 1">
          <NotasEjaFundamentalUm
            :tipoTabela="tipoTabela"
            :isLoadingPage="isLoadingPage"
            :displayedRecords="displayedRecords"
            :acesso="acesso"
            :encerrado="encerrado"
            @ajustarInfo="ajustarInfo"
            @adicionarEditarRecuperacaoFinal="adicionarEditarRecuperacaoFinal"
            @adicionarEditarSimulado="adicionarEditarSimulado"
            @adicionarEditarAtividade="adicionarEditarAtividade"
            @adicionarEditarAvaliacao="adicionarEditarAvaliacao"
            @adicionarEditarRecuperacao="adicionarEditarRecuperacao"
            @mudarParaRetidoOuDependencia="mudarParaRetidoOuDependencia"
            :notaconfigperiodo="notaconfigperiodo"
            :estiloTabelaEJA ="1"
            @sendNotes="sendNotes"
            @reset="reset"
          />
        </div>
      </div>
      <questionario-nee
        :acesso="acesso"
        :formAlunoComNEE="formAlunoComNEE"
        :showModal="showModal"
        @verificarEditarNee="verificarEditarNee"
        :paraEditarNEE="paraEditarNEE"
        @alunocomNEE="alunocomNEE"
      />
    </div>
    <div v-if="pageReload">
      <div class="col-md-12" style="text-align: center; padding-top: 255px">
        <pm-ProgressSpinner />
      </div>
    </div>
  </section>
</template>


<script>
import axios from 'axios';
import { defineComponent } from 'vue'
import { Escola } from "@/class/escolas";
import { Verificar } from "@/class/verificar.js";
import { Turma } from "@/class/turma";
import { DisciplinaEscolar } from "@/class/disciplina";
import { Calendario } from "@/class/calendario";
import { SegmentoEscolar } from "@/class/segmentoEscolar";
import { Nota } from "@/class/nota";
import { CalcularNota } from "@/class/calcularNotas";
import { Nee } from "@/class/nee";
import { SeriesEscolar } from "@/class/serie";
import { LiberacaoDiario } from "@/class/LiberacaoDiario.js";
import { Configuracao } from "@/class/configuracao";


import NotasEjaFundamentalDois from '@/components/notas/notasEjaFundamentalDois.vue';
import NotasEjaFundamentalUm from '@/components/notas/notasEjaFundamentalUm.vue';
import NotasFundamentalDois from '@/components/notas/notasFundamentalDois.vue';
import NotasFundamentalUmOuERN from '@/components/notas/notasFundamentalUmOuERN.vue';

import questionarioNee  from '@/components/notas/questionarioNee.vue'
import { FuncoesEmComum } from "@/pages/admin/pages/notas/FuncoesEmComum";

export default defineComponent({
  components: {

    NotasEjaFundamentalDois,
    NotasEjaFundamentalUm,
    NotasFundamentalDois,
    NotasFundamentalUmOuERN,

    questionarioNee
  },
  props: {
    escola_id_st:{
      required: true
    },
    segmento_id_st:{
      required: true
    },
    periodo_id_st:{
      required: true
    },
    turma_id_st:{
      required: true
    },
    pg:{}
  },
  data () {
    const columns = [
      { key: "aluno", label: "Aluno", sortable: true },
      { key: "disciplina", label: "Disciplina", sortable: true },
      { key: "periodo_1", label: "1° Periodo"},
      { key: "periodo_2", label: "2° Periodo"},
      { key: "periodo_3", label: "3° Periodo"},
      { key: "periodo_4", label: "4° Periodo"},
      { key: "recuperacao_final", label: "Recuperacao Final"},
      { key: "passou", label: "Passou"},
    ];
    return {
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Notas dos Alunos'},
      ],
      base_url: axios.defaults.baseURL.replace('/api/v1',''),
      info: {
        escola_id:null,
        segmento_id:null,
        serie_id:null,
        turma_id:null,
        disciplina_id:null
      },
      notas:[],
      columns,
      escola:null,
      escolas:[],
      turma:null,
      turmas: [],

      escola_id: "",
      segmentoInfo: "",
      serieInfo: "",
      disciplina: "",
      turma_id: null,

      segmentoALL: [],
      serieALL: [],
      turmaALL: [],
      alunoALL:[],
      encerrado:[],
      p1:false,
      p2:false,
      p3:false,
      p4:false,
      acesso:{
        visualizar:1,
        cadastrar:1,
        editar:1,
        deletar:1,
      },
      paraEditarNEE:0,
      records:[],
      isLoadingPage:false,
      isLoading:false,
      tipoTabela:'',
      estiloTabelaEJA:'',
      anoAtual : '',
      mediaGeral : '',
      simulado : '',
      atividades : '',
      avaliacao : '',
      formAlunoComNEE: {
        aluno_id:'',
        nota_config_id:'',
        tipo:'',
        periodo:'',
        ano:'',
        suporte:'',
        compreensao:'',
        metodologia_utilizada:'',
        diagnostico_pedagogico:'',
        observacao:'',
      },
      showModal:false,
      notaconfigperiodo:'',
      periodoSelecionado:0,
      disciplinaALL:[],
      page: 1,
      perPage: 100,
      displayMaximizableEdit2:false,
      listaAutorizacaoALL:[],
      tipoDeAcao:-1,
      infoAutorizacao: {
        turma_id:-1,
        modulo:"notas",
        servidor_id:-1,
        qdt_dias:2,
      },
      displayMaximizable1:false,
      displayMaximizableEdit2:false,
      listPeriodos:[],
      professoresALL:[],
      configuracao:'',
      notasAlunos: {
        alunos:[],
        periodo: 0,
        turma: 0,
        ano: 0,
        disciplina: 0,
      },
      pageReload: false
    }
  },
  computed: {
    displayedRecords() {
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      if(endIndex != 'undefined'){
        return this.records.slice(startIndex, endIndex);
      }

    }
  },
  async beforeMount() {
    if (this.escola_id_st == undefined) {
      this.$router.push({ name: "coordenacao-turma-hab"});
    }
    this.listarEscolas();
    this.buscarSegmentoALL();
    this.visuPeriodosEncerrados();

    this.anoAtual = parseInt(sessionStorage.getItem("anoSelecionado"));


    const acesso = await Verificar.AcessoLocal(12);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;
  },
  methods:{
    reset() {
      this.notasAlunos = {
        alunos:[],
        periodo: 0,
        turma: 0,
        ano: 0,
        disciplina: 0,
      }
    },
    voltarParTurma() {
      this.$router.push({ name: "coordenacao-turma-hab",  params: { pg: this.pg }});
    },
    async buscarConfiguracoes() {
      let eja2 = 0;
      if (this.tipoTabela == 3 && this.estiloTabelaEJA == 1)
        {eja2 = 1;}

      //this.segmentoInfo
      const dt = await SegmentoEscolar.obtemUm(this.segmentoInfo);
      const data2 = await Configuracao.buscarConfiguracaoComTipo(dt.data.tipo, eja2);
      this.configuracao = data2.data;

      this.mediaGeral = this.configuracao.info.notas.media;
      this.simulado = this.configuracao.info.notas.simulado;
      this.atividades = this.configuracao.info.notas.atividades;
      this.avaliacao = this.configuracao.info.notas.avaliacao;
    },
    async mudarParaRetidoOuDependencia(record, tipo) {
      if (tipo == 1) {
        if (window.confirm("Você realmente deseja alterar de RETIDO para PROGRESSÃO PARCIAL?")) {
          const novo = {
            id: record.aluno_notas_completa.id,
          }
          const data = await Nota.alterarRetidoDependencia(novo);
          this.listarNotas();
        }
      }
      else if (tipo == 2) {
        if (window.confirm("Você realmente deseja alterar de PROGRESSÃO PARCIAL para RETIDO?")) {
          const novo = {
            id: record.aluno_notas_completa.id,
          }
          const data = await Nota.alterarRetidoDependencia(novo);
          this.listarNotas();
        }
      }
    },
      verificarSePeriodoJaTaEmAberto() {
        const periNumero = parseInt(this.infoAutorizacao.periodo_semestre[0]);
        let podeCadastrar = true;
        if (!this.encerrado.p1 && periNumero == 1) {
          podeCadastrar = false;
        }
        else if (!this.encerrado.p2 && periNumero == 2) {
          podeCadastrar = false;

        }
        else if (!this.encerrado.p3 && periNumero == 3) {
          podeCadastrar = false;

        }
        else if (!this.encerrado.p4 && periNumero == 4) {
          podeCadastrar = false;

        }
        if (podeCadastrar) {
          return true;
        }
        else {
          this.displayMaximizable1 = false;
          this.$vaToast.init({
              message: "O período selecione já está aberto!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              color: 'warning' ,
              duration: 3000,
              fullWidth: false,
          })
          return false;
        }

      },
      async apagarAutorizacao(data) {
        try {
          if (window.confirm("Você realmente deseja apagar a autorização selecionada?")) {
            try {
              const data1 = await LiberacaoDiario.deletarAutorizacao(data.id);
              this.$vaToast.init({
                message: "Removido com sucesso!",
                iconClass: 'fa-star-o',
                position: 'top-right',
                color: 'success' ,
                duration: 2500,
                fullWidth: false,
              })
              this.listarAuto();
            } catch (e) {
              const a = e.response.data.erro;
                setTimeout(() => {
                  this.$vaToast.init({
                    message: a,
                    iconClass: 'fa-star-o',
                    position: 'top-right',
                    duration: 3500,
                    fullWidth: false,
                    color: 'danger'
                  });
                }, 500);
            }
          }
        }
        catch(e) {
        }
      },
      async editarAutorizacao() {
      try {
        this.infoAutorizacao.servidor_id = this.infoAutorizacao.servidor_id.id;
        this.infoAutorizacao.periodo_semestre = this.infoAutorizacao.periodo;

        //verificar se ja ta aberto
        if(!this.verificarSePeriodoJaTaEmAberto())
          {return false;}

        const data = await LiberacaoDiario.editarLiberacao(this.infoAutorizacao);
        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.limparCamposAutorizacao();
        this.listarAuto();
        this.displayMaximizable1 = false;
      }
      catch(e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value,
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          //this.msg_erro = e.response.data;
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a,
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
        this.limparCamposAutorizacao();
        this.displayMaximizable1 = false;
      }

    },
    async editarAutori(data) {
      this.tipoDeAcao = 2;
      this.buscarPeriodosDisponi();
      this.infoAutorizacao.id = data.id;
      this.infoAutorizacao.turma_id = data.turma_id;
      this.infoAutorizacao.servidor_id = this.professoresALL.find(element => element.id == data.servidor_id);

      this.infoAutorizacao.periodo = data.periodo_semestre;
      this.displayMaximizable1 = !this.displayMaximizable1;
    },
    async salvarAutorizacao() {
      this.infoAutorizacao.turma_id = this.turma_id;
      this.infoAutorizacao.servidor_id =   this.infoAutorizacao.servidor_id.id;
      this.infoAutorizacao.periodo_semestre = this.infoAutorizacao.periodo;
      this.infoAutorizacao.mes_liberado = "";
      //verificar se ja ta aberto
      if(!this.verificarSePeriodoJaTaEmAberto())
        {return false;}
      try {
        const data = await LiberacaoDiario.CadastrarDiario(this.infoAutorizacao);
        this.$vaToast.init({
          message: "Salvo com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.limparCamposAutorizacao();
        this.listarAuto();
        this.displayMaximizable1 = false;
      }
      catch(e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value,
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          //this.msg_erro = e.response.data;
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a,
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
        this.limparCamposAutorizacao();
        this.displayMaximizable1 = false;
      }
    },
    async buscarProfessores() {
      const data = await Turma.buscarProfessoresDaTurma(this.turma_id);
      this.professoresALL = [];
      for (const el of data.data) {
        el.nome_todo = el.nome + ' ' +  el.sobrenome;
        this.professoresALL.push(el);
      }
    },
    limparCamposAutorizacao() {
      this.infoAutorizacao.turma_id = null;
      this.infoAutorizacao.servidor_id =  '';
      this.infoAutorizacao.periodo_semestre = "";
    },
    buscarPeriodosDisponi() {
      this.listPeriodos = [];
      this.listPeriodos.push("1º Período");
      this.listPeriodos.push("2º Período");
      this.listPeriodos.push("3º Período");
      this.listPeriodos.push("4º Período");
    },
    cadastrarInfo() {
      //cadastar
      this.limparCamposAutorizacao();
      this.tipoDeAcao = 1;
      this.listPeriodos = [];
      //liberar apenas peridos que estao fechados
      this.buscarPeriodosDisponi();
      this.displayMaximizable1 = !this.displayMaximizable1;
    },
    async listarAuto() {
      const data = await LiberacaoDiario.visualizarLiberacao("notas");
      this.listaAutorizacaoALL = [];
      for (const el of data.data) {
        el.nome = el.servidor.nome + ' ' + el.servidor.sobrenome;
        el.periodo = el.periodo_semestre;
        this.listaAutorizacaoALL.push(el);
      }
    },
    async openModalAutorizacao() {
      this.listarAuto();
      //buscar lista de autorizaçã
      this.displayMaximizableEdit2 = !this.displayMaximizableEdit2;
    },
    ajustarInfo(aluno_id) {
      this.formAlunoComNEE.ano = this.anoAtual;
      this.formAlunoComNEE.aluno_id = aluno_id;
      this.formAlunoComNEE.tipo = '';
      this.formAlunoComNEE.suporte = '';
      this.formAlunoComNEE.compreensao = '';
      this.formAlunoComNEE.metodologia_utilizada = '';
      this.formAlunoComNEE.diagnostico_pedagogico = '';
      this.formAlunoComNEE.observacao = '';
      this.showModal = !this.showModal;
    },
    async listarNotas() {
      try {
        this.pageReload = true;
        const anoSelect = sessionStorage.getItem("anoSelecionado");
        const data = await Nota.ListarNotaAlunos(
          this.info.turma_id,
          this.info.disciplina_id,
          anoSelect
        );
        this.records = data.data;

        if (!this.records.length) {
          this.$vaToast.init({
            message: "Verifique se a turma está ativa ou se possui alunos!",
            iconClass: "fa-star-o",
            position: "top-right",
            color: "warning",
            duration: 3000,
            fullWidth: false,
          });
          this.pageReload = false;
          return false;
        }
        try {
          this.records = await FuncoesEmComum.listarNotasFunction(this.records, this.configuracao.info.notas.recuperacao_bimestral, this.configuracao, this.mediaGeral, this.tipoTabela, this.estiloTabelaEJA);
        }
        catch(e) {
        }
        this.pageReload = false;
      } catch (e) {

      }
    },

    async listaDisciplina(turma){
      const data = await DisciplinaEscolar.obtemTodosSemId1Turma(turma);
      this.disciplinaALL = [];
      for (const el of data.data) {
        if(el.na_turma == 1){
          const novo = {
            id: el.id,
            nome: el.nome,
            status: el.status
          }
          this.disciplinaALL.push(novo);
        }
      }
    },

    async verificarPeriodosTurmaDisciplina(){
      const dt = await Nota.GetConfigPeriodo(this.turma_id,  this.info.disciplina_id);
      this.notaconfigperiodo = dt.data;
    },
    async buscarInfoNotaMedia() {
      const data = await Configuracao.buscarConfiguracaoPeloSegmento(this.segmentoInfo);
      this.mediaGeral = data.data.media;
      this.simulado = data.data.simulado;
      this.atividades = data.data.atividades;
      this.avaliacao = data.data.avaliacao;
    },
    async verificarEditarNee(tipo) {
      const novo1 = {
          aluno_id: this.formAlunoComNEE.aluno_id,
          disciplina_id: this.info.disciplina_id,
          ano: this.anoAtual,
          turma_id: this.info.turma_id
      }
      const data1 = await Nota.GetNotaConfig(novo1);
      const novo2 = {
        aluno_id: this.formAlunoComNEE.aluno_id,
        nota_config_id: data1.data.id,
        periodo: this.periodoSelecionado + 1,
        ano: this.formAlunoComNEE.ano,
        tipo: tipo,
      }
      const data2 = await Nee.obtemUmSemId(novo2);
      //cadastrar informacao
      if (data2.data == -1) {
        this.formAlunoComNEE.id = '';
        this.formAlunoComNEE.suporte = '';
        this.formAlunoComNEE.compreensao = '';
        this.formAlunoComNEE.metodologia_utilizada = '';
        this.formAlunoComNEE.diagnostico_pedagogico = '';
        this.formAlunoComNEE.observacao = '';
        this.paraEditarNEE = 0;
      }
      else {
        this.formAlunoComNEE.id = data2.data.id;
        this.formAlunoComNEE.aluno_id = data2.data.aluno_id;
        this.formAlunoComNEE.nota_config_id  = data2.data.nota_config_id;
        this.formAlunoComNEE.periodo = data2.data.periodo;
        this.formAlunoComNEE.suporte = (data2.data.suporte == 0 ? '0' : '1');
        this.formAlunoComNEE.compreensao = (data2.data.compreensao == 0 ? '0' : '1');
        this.formAlunoComNEE.metodologia_utilizada = data2.data.metodologia_utilizada;
        this.formAlunoComNEE.diagnostico_pedagogico = data2.data.diagnostico_pedagogico;
        this.formAlunoComNEE.observacao = data2.data.observacao;
        this.paraEditarNEE = 1;
      }

    },

    async sendNotes(periodoSelecionado) {
      this.notasAlunos.ano = this.anoAtual,
      this.notasAlunos.turma = this.info.turma_id,
      this.notasAlunos.disciplina = this.info.disciplina_id,
      this.notasAlunos.periodo = periodoSelecionado;
      try {
        if(this.notasAlunos.alunos.length > 0){

          const res = await Nota.enviarNotaAlunos(this.notasAlunos)

          if (res.statusCode === 200 || res.status === 200) {
            this.notasAlunos = {
              alunos:[],
              periodo: 0,
              turma: 0,
              ano: 0,
              disciplina: 0,
            }
            this.$vaToast.init({
              message: "Notas lançadas com sucesso!",
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 3500,
              fullWidth: false,
              color: "success",
            });
          } else {
            this.$vaToast.init({
              message: "Ocorreu um problema " + res.statusCode,
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 3500,
              fullWidth: false,
              color: "warning",
            });
          }
          this.listarNotas();
        }else{
          this.$vaToast.init({
              message: "Adicione ou altere uma nota antes de lançar.",
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 3500,
              fullWidth: false,
              color: "warning",
            });
        }
      } catch (error) {
        this.$vaToast.init({
            message: "Ocorreu um erro ao lançar as notas!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3500,
            fullWidth: false,
            color: "danger",
          });
      }
    },
    async adicionarEditarAtividade(
      aluno_id_selecionado,
      nota,
      qualTipo,
      idAtividade,
      configuracao,
      periodoSelecionado
    ) {
      if (nota < 0 || nota > configuracao.info.notas.atividades) {
        this.$vaToast.init({
          message:
            "Digite um valor entre 0 e " +
            configuracao.info.notas.atividades +
            "!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3500,
          fullWidth: false,
          color: "warning",
        });
        // this.listarNotas();
        return 0;
      }
      let notaVazia = false;
      if (nota === undefined || nota === null || nota === "") {
        notaVazia = true;
      }

      try {
        //Verificar config
        const novo1 = {
          aluno_id: aluno_id_selecionado,
          disciplina_id: this.info.disciplina_id,
          ano:this.anoAtual,
          turma_id: this.info.turma_id
        };
        const data1 = await Nota.GetNotaConfig(novo1);
        //Atividade diversificada

        const novo2 = {
          id: idAtividade,
          nota_config_id: data1.data.id,
          periodo: periodoSelecionado,
          nome: "Atividade Diversificada",
          atividade: nota,
          tipo: qualTipo,
        }

        const atividadeAluno = {
          alunoId: novo1.aluno_id,
          atividade:{
            idAtividade: novo2.id,
            nota: novo2.atividade,
          }
        }

        const index = this.notasAlunos.alunos.findIndex(aluno => aluno.alunoId === atividadeAluno.alunoId);

        if (notaVazia == false) {
          if (qualTipo == 1) {
            if (index !== -1) {
              this.notasAlunos.alunos[index].atividade = atividadeAluno.atividade;
            } else {
              // this.notasAlunos.alunos[index].atividade.push(atividadeAluno);
              this.notasAlunos.alunos.push(atividadeAluno);
            }
            // const data2 = await Nota.AddAtividadesComplementares(novo2);
          } else {
            if (index !== -1) {
              this.notasAlunos.alunos[index].atividade = atividadeAluno.atividade;
            } else {
              // this.notasAlunos.alunos[index].atividade.push(atividadeAluno);
              this.notasAlunos.alunos.push(atividadeAluno);
            }
            // const data2 = await Nota.AddAtividadesComplementares(novo2);
          }
          // this.listarNotas();
        } else {
          if (qualTipo == 2) {
            if (index !== -1 && this.notasAlunos.alunos[index].atividade) {
              this.notasAlunos.alunos[index].atividade.nota = null;
            }
            else if(index !== -1){
              atividadeAluno.atividade.nota = null
              this.notasAlunos.alunos[index].atividade = atividadeAluno.atividade;
            }else{
              atividadeAluno.atividade.nota = null
              this.notasAlunos.alunos.push(atividadeAluno);

            }

          }else{
            if (index !== -1 && this.notasAlunos.alunos[index].atividade) {
              this.notasAlunos.alunos[index].atividade.nota = null;

            }
          }
        }
        if (notaVazia == true && qualTipo == 1) {
          return false;
        }

      } catch (e) {
        console.log(e)
        this.$vaToast.init({
          message: "Error ao adicionar atividade",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3500,
          fullWidth: false,
          color: "danger",
        });
      }
    },
    async alunocomNEE(cadastrarOuEditar) {
        //Apenas editar
        if (cadastrarOuEditar == 1) {
          try {
            const data1 = await Nee.alterar(this.formAlunoComNEE);
            this.$vaToast.init({
              message: "Editado com sucesso!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3500,
              fullWidth: false,
              color: 'success'
            })
          }
          catch(e) {
            this.$vaToast.init({
              message: "Problema ao editar!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3500,
              fullWidth: false,
              color: 'danger'
            })
          }
          this.showModal = !this.showModal;
          return 0;
        }
        try {
          //Verificar config
          const novo1 = {
            aluno_id: this.formAlunoComNEE.aluno_id,
            disciplina_id:  this.info.disciplina_id,
            ano:this.anoAtual,
            turma_id: this.info.turma_id
          }
          const data1 = await Nota.GetNotaConfig(novo1);
          //Atividade diversificada

          this.formAlunoComNEE.nota_config_id = data1.data.id;
          this.formAlunoComNEE.periodo =  this.periodoSelecionado + 1;
          const data = await Nee.cadastrar(this.formAlunoComNEE);
          this.$vaToast.init({
              message: "Cadastrado com sucesso!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3500,
              fullWidth: false,
              color: 'success'
          })
        }
        catch(e) {
          this.$vaToast.init({
              message: "Problema ao cadastrar!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3500,
              fullWidth: false,
              color: 'danger'
            })
        }
      this.showModal = !this.showModal;
    },
    async adicionarEditarSimulado(
      aluno_id_selecionado,
      nota,
      qualTipo,
      idSimulado,
      configuracao,
      periodoSelecionado
    ) {
      if (nota < 0 || nota > configuracao.info.notas.simulado) {
        this.$vaToast.init({
          message:
            "Digite um valor entre 0 e " +
            configuracao.info.notas.simulado +
            "!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3500,
          fullWidth: false,
          color: "warning",
        });
        return 0;
      }

      let notaVazia = false;
      if (nota === undefined || nota === null || nota === "") {
        notaVazia = true;
      }

      try {
        //Verificar config
        const novo1 = {
          aluno_id: aluno_id_selecionado,
          disciplina_id: this.info.disciplina_id,
          ano: this.anoAtual,
          turma_id: this.info.turma_id,
        };
        const data1 = await Nota.GetNotaConfig(novo1);
        //Atividade diversificada

        const novo2 = {
          id: idSimulado,
          nota_config_id: data1.data.id,
          periodo: periodoSelecionado,
          nome: "Simulado",
          simulado: nota,
          tipo: qualTipo,
          ano: this.anoAtual,
        };

        const simuladoAluno = {
          alunoId: novo1.aluno_id,
          simulado: {
            idSimulado: novo2.id,
            nota: novo2.simulado,
          }
        }

        const index = this.notasAlunos.alunos.findIndex(aluno => aluno.alunoId === simuladoAluno.alunoId);

        if (notaVazia == false) {
          if (qualTipo == 1) {

            if (index !== -1) {
              this.notasAlunos.alunos[index].simulado = simuladoAluno.simulado;
            } else {
              this.notasAlunos.alunos.push(simuladoAluno);
            }
          } else {

            if (index !== -1) {
              this.notasAlunos.alunos[index].simulado = simuladoAluno.simulado;
            } else {
              this.notasAlunos.alunos.push(simuladoAluno);
            }
          }
        } else {
          if (qualTipo == 2) {
            if (index !== -1 && this.notasAlunos.alunos[index].simulado) {
              this.notasAlunos.alunos[index].simulado.nota = null;
            }else if(index !== -1){
              simuladoAluno.simulado.nota = null
              this.notasAlunos.alunos[index].simulado = simuladoAluno.simulado;
            }else{
              simuladoAluno.simulado.nota = null
              this.notasAlunos.alunos.push(simuladoAluno);

            }

          }else{
            if (index !== -1 && this.notasAlunos.alunos[index].simulado) {
              this.notasAlunos.alunos[index].simulado.nota = null;

            }
          }
        }

        if (notaVazia == true && qualTipo == 1) {
          return false;
        }
      } catch (e) {
        this.$vaToast.init({
          message: "Error ao adicionar atividade",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3500,
          fullWidth: false,
          color: "danger",
        });
      }
    },
    async adicionarEditarAvaliacao(
      aluno_id_selecionado,
      nota,
      qualTipo,
      idAvaliacao,
      configuracao,
      periodoSelecionado
    ) {
      if (nota < 0 || nota > configuracao.info.notas.avaliacao) {
        this.$vaToast.init({
          message:
            "Digite um valor entre 0 e " +
            configuracao.info.notas.avaliacao +
            "!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3500,
          fullWidth: false,
          color: "warning",
        });
        return 0;
      }
      let notaVazia = false;
      if (nota === undefined || nota === null || nota === "") {
        notaVazia = true;
      }
      //Verificar config
      try {
        //Verificar config
        const novo1 = {
          aluno_id: aluno_id_selecionado,
          disciplina_id: this.info.disciplina_id,
          ano: this.anoAtual,
          turma_id: this.info.turma_id,
        };
        const data1 = await Nota.GetNotaConfig(novo1);
        const novo2 = {
          id: idAvaliacao,
          nota_config_id: data1.data.id,
          periodo: periodoSelecionado,
          avaliacao: nota,
          tipo: qualTipo,
          ano: this.anoAtual,
        };
        const provaAluno = {
          alunoId: novo1.aluno_id,
          prova: {
            idProva: novo2.id,
            nota: novo2.avaliacao,
          }
        }

        const index = this.notasAlunos.alunos.findIndex(aluno => aluno.alunoId === provaAluno.alunoId);

        if (notaVazia == false) {
          if (qualTipo == 1) {

            if (index !== -1) {
              this.notasAlunos.alunos[index].prova = provaAluno.prova;
            } else {
              this.notasAlunos.alunos.push(provaAluno);
            }
          } else {

            if (index !== -1) {
              this.notasAlunos.alunos[index].prova = provaAluno.prova;
            } else {
              this.notasAlunos.alunos.push(provaAluno);
            }
          }
        } else {
          if (qualTipo == 2) {

            if (index !== -1 && this.notasAlunos.alunos[index].prova) {
              this.notasAlunos.alunos[index].prova.nota = null;
            }else if(index !== -1){
              provaAluno.prova.nota = null
              this.notasAlunos.alunos[index].prova = provaAluno.prova;
            }
            else{
              provaAluno.prova.nota = null
              this.notasAlunos.alunos.push(provaAluno);

            }

          }else{
            if (index !== -1 && this.notasAlunos.alunos[index].prova) {
              this.notasAlunos.alunos[index].prova.nota = null;

            }
          }
        }
        if (notaVazia == true && qualTipo == 1) {
          return false;
        }
      } catch (e) {
        this.$vaToast.init({
          message: "Error ao adicionar avalição",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3500,
          fullWidth: false,
          color: "danger",
        });
      }
    },
    async adicionarEditarRecuperacao(
      aluno_id_selecionado,
      nota,
      qualTipo,
      idRecuperacao,
      configuracao,
      periodoSelecionado
    ) {

      let recuperacaoSemestral = "";
      //Verificar se eh bimestral ou semestral
      if (configuracao.info.notas.recuperacao_bimestral) {
        if (periodoSelecionado == 1) {
          recuperacaoSemestral = configuracao.info.notas.recuperacao_nota_b1; //pegar o valor da recuperacao do bimestre 1
        } else if (periodoSelecionado == 2) {
          recuperacaoSemestral = configuracao.info.notas.recuperacao_nota_b2; //pegar o valor da recuperacao do bimestre 2
        } else if (periodoSelecionado == 3) {
          recuperacaoSemestral = configuracao.info.notas.recuperacao_nota_b3; //pegar o valor da recuperacao do bimestre 3
        } else if (periodoSelecionado == 4) {
          recuperacaoSemestral = configuracao.info.notas.recuperacao_nota_b4; //pegar o valor da recuperacao do bimestre 4
        }
      } else {
        if (periodoSelecionado == 2) {
          recuperacaoSemestral = configuracao.info.notas.recuperacao_nota_s1; //pegar o valor da recuperacao do semestre 1
        } else if (periodoSelecionado == 4) {
          recuperacaoSemestral = configuracao.info.notas.recuperacao_nota_s2; //pegar o valor da recuperacao do semestre 2
        }
      }
      if (nota < 0 || nota > recuperacaoSemestral) {
        this.$vaToast.init({
          message: "Digite um valor entre 0 e " + recuperacaoSemestral + "!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3500,
          fullWidth: false,
          color: "warning",
        });
        // this.listarNotas();
        return 0;
      }
      let notaVazia = false;
      if (nota === undefined || nota === null || nota === "") {
        notaVazia = true;
      }
      try {
        //Verificar config
        const novo1 = {
          aluno_id: aluno_id_selecionado,
          disciplina_id: this.info.disciplina_id,
          ano: this.anoAtual,
          turma_id: this.info.turma_id,
        };
        const data1 = await Nota.GetNotaConfig(novo1);
        const novo2 = {
          id: idRecuperacao,
          nota_config_id: data1.data.id,
          periodo: periodoSelecionado,
          avaliacao: nota,
          tipo: qualTipo,
          ano: this.anoAtual,
        };

        const recuperacaoAluno = {
          alunoId: novo1.aluno_id,
          recuperacao:{
            idRecuperacao: novo2.id,
            nota: novo2.avaliacao,
          }
        }

        const index = this.notasAlunos.alunos.findIndex(aluno => aluno.alunoId === recuperacaoAluno.alunoId);

        if (notaVazia == false) {
          if (index !== -1) {
              this.notasAlunos.alunos[index].recuperacao = recuperacaoAluno.recuperacao;
            } else {
              // this.notasAlunos.alunos[index].atividade.push(atividadeAluno);
              this.notasAlunos.alunos.push(recuperacaoAluno);
            }
          // const data2 = await Nota.AddRecuperacao(novo2);
        } else {
          if (qualTipo == 2) {
             if (index !== -1 && this.notasAlunos.alunos[index].recuperacao) {
              this.notasAlunos.alunos[index].recuperacao.nota = null;
            }
            else if(index !== -1){
              recuperacaoAluno.recuperacao.nota = null
              this.notasAlunos.alunos[index].recuperacao = recuperacaoAluno.recuperacao;
            }else{
              recuperacaoAluno.recuperacao.nota = null
              this.notasAlunos.alunos.push(recuperacaoAluno);

            }

          }
        }
        if (notaVazia == true && qualTipo == 1) {
          return false;
        }

      } catch (e) {
        this.$vaToast.init({
          message: "Error ao adicionar avalição",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3500,
          fullWidth: false,
          color: "danger",
        });
      }
    },
    async adicionarEditarRecuperacaoFinal(record, recuperacao, tipo) {
      if (recuperacao < 0 || recuperacao > 100) {
        this.$vaToast.init({
          message: "Digite um valor entre 0 e 100!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'warning'
        })
        // this.listarNotas();
        return 0;
      }
      let notaVazia = false;
      if (
        recuperacao === undefined ||
        recuperacao === null ||
        recuperacao === ""
      ) {

        recuperacao = null;
        notaVazia = true;
      }
      const elePassou = Math.max(recuperacao, record.mediaFinal) >= this.mediaGeral;
      const novo2 = {
        aluno_notas_id: record.aluno_notas_id,
        disciplina_id:   this.info.disciplina_id,
        periodo_1:  String(record.media1),
        periodo_2:  String(record.media2),
        periodo_3:  String(record.media3),
        periodo_4:  String(record.media4),
        recuperacao_final: recuperacao,
        media_final: Math.max(recuperacao, record.mediaFinal),
        passou: elePassou,
      }

      const recuperacaoFinalAluno = {
        alunoId: record.aluno_id,
        recuperacaoFinal: {
          alunoNotaId: novo2.aluno_notas_id,
          nota: novo2.recuperacao_final,
        }
      }
      const index = this.notasAlunos.alunos.findIndex(aluno => aluno.alunoId === recuperacaoFinalAluno.alunoId);
      if (notaVazia == false) {
        if (tipo == 1) {
          if (index !== -1) {
            this.notasAlunos.alunos[index].recuperacaoFinal = recuperacaoFinalAluno.recuperacaoFinal;
          } else {
            this.notasAlunos.alunos.push(recuperacaoFinalAluno);
          }
        } else {

          if (index !== -1) {
            this.notasAlunos.alunos[index].recuperacaoFinal = recuperacaoFinalAluno.recuperacaoFinal;
          } else {
            this.notasAlunos.alunos.push(recuperacaoFinalAluno);
          }
        }
      } else {
        if (tipo == 2) {
          if (index !== -1 && this.notasAlunos.alunos[index].recuperacaoFinal) {
            this.notasAlunos.alunos[index].recuperacaoFinal.nota = null;
          }else if(index !== -1){
            recuperacaoFinalAluno.recuperacaoFinal.nota = null
            this.notasAlunos.alunos[index].recuperacaoFinal = recuperacaoFinalAluno.recuperacaoFinal;
          }else{
            recuperacaoFinalAluno.recuperacaoFinal.nota = null
            this.notasAlunos.alunos.push(recuperacaoFinalAluno);

          }

        }else{
          if (index !== -1 && this.notasAlunos.alunos[index].recuperacaoFinal) {
            this.notasAlunos.alunos[index].recuperacaoFinal.nota = null;

          }
        }
      }
      // if (tipo == 1) {
      //   const data2 = await Nota.adicionarNotaAlunoDisciplina(novo2);
      // }
      // else {
      //   const data2 = await Nota.editarNotaAlunoDisciplina(novo2);
      // }
      // this.listarNotas();
    },
    limparCampos(){
      this.periodoSelecionado = 0;
      this.notas =[],
      this.segmentoALL = [];
      this.serieALL = [];
      this.turmaALL = [];
      this.turma_id= null;
      this.segmentoInfo = '';
      this.serieInfo = '';
      this.disciplina = '';
      this.info.disciplina_id = null;
      this.buscarSegmentoALL();
    },
    async listarEscolas(){
      const esc = await Escola.obtemTodos();
      this.escolas = esc.data;
    },
    async turma_disciplinas(turma) {
      this.turma = turma;
      this.alunos = [];
      this.disciplina = '';
      this.info.disciplina_id = null;
      this.disciplinaALL = [];
      const data1 = await DisciplinaEscolar.obtemTodosSemId1Turma(this.turma);
      for (const el of data1.data) {
        if(el.na_turma == 1){
          const novo = {
            id: el.id,
            nome: el.nome,
            status: el.status
          }
          this.disciplinaALL.push(novo);
        }
      }
    },
    async buscarTipoDeTabela() {
      const data2 = await SegmentoEscolar.obtemUm(this.segmentoInfo);
      this.tipoTabela = data2.data.tipo;
      if (this.tipoTabela == 3) {
        const data3 = await SeriesEscolar.obtemUm(this.serieInfo);
        if (data3.data.tipo == 1) {
          this.estiloTabelaEJA = 0;
        }
        else {
          this.estiloTabelaEJA = 1;
        }
      }
    },
    async buscarNotasDoAluno() {
      try {
        this.tipoTabela = '';
        this.periodoSelecionado = 0;
        this.info.escola_id = this.escola_id;
        this.info.segmento_id = this.segmentoInfo;
        this.info.serie_id = this.serieInfo;
        this.info.turma_id = this.turma_id;
        this.info.disciplina_id = this.disciplina.id;
        await this.verificarPeriodosTurmaDisciplina();
        await this.buscarTipoDeTabela();
        await this.listarNotas();
      }
      catch(e) {

      }
    },
    async buscarSegmentoALL() {
      const data = await SegmentoEscolar.obtemTodos();
      this.segmentoALL = [];
      for (const el of data.data) {
        if (el.id == 5)
          {continue;}
        this.segmentoALL.push(el);
      }
    },

    async buscarSegmentoALLNovo() {
      this.segmentoInfo=  "";
      this.serieInfo=  "";
      this.disciplina= "";
      this.turma_id=  null;

      this.turmaALL = [];
      this.serieALL = [];
      this.segmentoALL = [];
      const data = await SegmentoEscolar.obtemSegmentosEscola(this.escola_id);
      this.segmentoALL = [];
      this.segmentoALL = data.data;
    },

    async listaSerie (id) {
      const data = await SegmentoEscolar.listarSegmentosPorSerie(id);
      this.disciplina = '';
      this.info.disciplina_id = null;
      this.turma_id = null;
      this.serieInfo = '';
      this.disciplinaALL = [];
      this.serieALL = data.data;
      // this.buscarInfoNotaMedia();
    },

    async listaTurmas(){
      const play = {
        'escola':this.escola_id,
        'serie': this.serieInfo,
        'segmento': this.segmentoInfo,
      };
      this.buscarConfiguracoes();
      this.disciplina = '';
      this.info.disciplina_id = null;
      this.turma_id = null;
      this.disciplinaALL = [];
      const data = await Turma.pesqTurmaPorSerieSegmento(play);
      this.turmaALL = data.data;
    },

    async listarAlunosdaTurma(){
      const data = await Turma.alunoEmUmaTurma(this.turma);
      this.alunoALL = data.data;
    },

    async visuPeriodosEncerrados(){
      const data = await Nota.VisuPeriodosEncerrar();
      this.encerrado = data.data;
      if(this.encerrado.p1){this.p1 = false;}else{this.p1 = true;}
      if(this.encerrado.p2){this.p2 = false;}else{this.p2 = true;}
      if(this.encerrado.p3){this.p3 = false;}else{this.p3 = true;}
      if(this.encerrado.p4){this.p4 = false;}else{this.p4 = true;}

    },
    async encerrar_reabrir(n){
      try {
           let valor;
          if(n == 1){ if(this.p1 == true){ valor = 1}else{valor = 0}}
          if(n == 2){ if(this.p2 == true){ valor = 1}else{valor = 0}}
          if(n == 3){ if(this.p3 == true){ valor = 1}else{valor = 0}}
          if(n == 4){ if(this.p4 == true){ valor = 1}else{valor = 0}}
          const payload = {
            valor: valor,
            periodo: n,
          }
          const data = await Nota.EncerrarPeriodos(payload);
          this.encerrado = [];
          this.visuPeriodosEncerrados();
          this.limparCampos();
      }
      catch(e) {
        this.$vaToast.init({
            message: "Encerre o(s) período(s) anteriores!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3500,
            fullWidth: false,
            color: 'danger'
        });
        this.visuPeriodosEncerrados();
        this.limparCampos();
      }
    }
  },
})
</script>

<style>
  @media(max-width:576px){
    .va-modal__container{
      width:100% !important;
      height:45%;
    }
    .va-modal--mobile-fullscreen {
      min-height: 0vh!important;
    }
  }
</style>

<style scoped>
  .th_per1{
    background-color: #f8f9fa;
    color: #495057;
    border-right: 1px solid #e9ecef;
    border-bottom: 2px solid #e9ecef;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
    vertical-align: middle;
  }

  .th_esp{
    width:15px;
    background-color: #3f448700;
    border-bottom: 2px solid #fff;
  }
  .cabecario-turma{
    background-color:#4da4e9;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }

  .tbl_situacao_apv{
    font-weight: 600;
    color: #539b53;
  }
  .tbl_situacao_rep{
    font-weight: 600;
    color: #b93b3b;
  }
</style>

<style scoped>

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 23px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

  .mtwidth{
    width:10%;
  }
  .tdmt{
    text-align: center;font-weight: 800;color: #2b72fd;
  }

  tr td{
    vertical-align: middle;
  }


</style>


<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .th_prf{
    background-color: #0067a7 !important;
    color: #fff;
    text-align: left;
    padding: 15px !important;
    padding-left: 25px !important;
    border: 1px solid;
  }
  .th_prf1{
    background-color:  #2e92d1 !important;
    color: #fff;
    text-align: left;
    padding: 15px !important;
    padding-left: 25px !important;
    border: 1px solid;
  }
  .tbody_prf{
    border-top: 0px solid currentColor !important;
  }
</style>

<style>
 .compl{font-size: 12px !important;}
</style>
